<template>
  <ul class="itemDetails">
    <li
      v-for="(item, index) in orderList"
      :key="index"
      @touchend="choiseExpenseItem(index)"
    >
      <div class="topMain">
        <div class="infoBox">
          <div class="flex">
            <div class="supplerImgBox">
              <img
                v-if="item.itsItemData.supplierThumbnail"
                :src="item.itsItemData.supplierThumbnail"
              />
              <img v-else src="../../../assets/images/icar.png" />
            </div>
            <p>{{ item.supplierName }}</p>
          </div>
          <div class="borderBox">
            <a>{{ item.carModus }}</a>
            <a>{{ item.carType }}</a>
            <a>{{ item.qty1 }}台</a>
          </div>

          <div class="timeBox">
            <div class='flex'>
              <svg class="icon-normal timeIcon" aria-hidden="true">
                <use xlink:href="#icon-shijian" />
              </svg>
              <span class="ml-5">{{ item.dtStart | dataFormat }}</span>
            </div>
            <span class="bold">
              <i>{{ item.amount || 0 | currencyFormatter }}</i>
            </span>
          </div>
        </div>
        <div class="addressBox" v-if="item.itsItemData.isSpecial">
          <div><i class="greenBc"></i>{{ item.itsItemData.departure }}</div>
          <div><i class="orangeBc"></i>{{ item.itsItemData.destination }}</div>
        </div>
        <div class="descBox">
          <span class="desctxtBox">{{ item.descr || "暂无备注" }}</span>
        </div>
      </div>

      <div class="btnBox">
        <!-- <svg
          class="icon"
          @click.stop="itemClick(item, btn)"
          aria-hidden="true"
          v-for="(btn, btnindex) in item.buttons"
          :key="btnindex"
        >
          <use
            :xlink:href="
              btn.processor == 'remove' ? '#iconqingkong' : '#iconbianji'
            "
          /> -->
          <div  @click.stop="itemClick(item, btn)"
          v-for="(btn, btnindex) in item.buttons"
          :key="btnindex">
          {{ btn.txt }}

          </div>
        <!-- </svg> -->
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    orderList: {
      type: Array,
    },
    itemClick: {
      type: Function,
    },
  },
  data() {
    return {
      timeer: null,
      flag: -1,
    };
  },
  beforeDestroy() {
    clearTimeout(this.timeer);
  },
  methods: {
    choiseExpenseItem(index) {
      console.log(index);
      this.flag = index;
      let _this = this;
      this.timeer = setTimeout(function() {
        _this.flag = -1;
      }, 500);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/tenant/item.scss";
</style>
