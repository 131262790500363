<template>
  <div class="itemsBox">
    <iHeader backType="blank" :backEvent="true" @back-event="itemsGoBack()" :back="true">
      <div slot="headText">
        <span class="head-txt">
          {{ titleTxt }}
        </span>
      </div>
    </iHeader>

    <div class="itemsMain">
      <itemInfo :orderList="orderList" :itemClick="itemClick"></itemInfo>
      <Skeleton v-if="isFirst" :skList="skList" />
      <NoData v-if="!isFirst && !orderList.length"></NoData>
      <div class="footBtns" @click="!isloding && SubmitAllItem()" v-if="checkCanNewItem.show && orderList.length > 0">
        <a :class="['MainsubmitBtn', isloding && 'disableBtn']">提交</a>
      </div>
    </div>
    <AddButton v-if="checkCanNewItem.show" :color="$publicFun.setColor()" @click="goEditOrder('')"></AddButton>
    <MessageBox ref="msg" type="tips" :messageTxt="messageTxt" @on-ok="messageBtn()"></MessageBox>
  </div>
</template>
<script>
import itemInfo from '@/views/common/tenant/itemInfo';
import vueFilter from '@/views/controllers/filter/vuefilter.js';
import filterEmoji from '@/views/controllers/filterEmoji.js';
export default {
  mixins: [filterEmoji],
  data() {
    return {
      skList: [
        {
          width: '65%',
          row: 5
        },
        {
          width: '20%',
          position: 'right'
        },
        {
          width: '40%',
          inline: true
        },
        {
          width: '50%'
        },
        {
          width: '30%',
          ml: '.1rem'
        },
        {
          width: '15%',
          inline: true
        },
        {
          width: '100%',
          inline: true
        },
        {
          width: '0',
          inline: true
        }
      ],
      isFirst: true,
      titleTxt: ['pfizer'].includes(this.$cookies.get('tenant')) ? '租车需求' : '城市车队需求',
      messageTxt: '确定删除该需求吗',
      messageType: '',
      orderList: [],
      flag: 0,
      isloding: false,
      choiseItem: {},
      isLoade: false,
      eventForm: {},
      checkCanNewItem: {}
    };
  },
  created() {
    if (this.SmartStorage.get('routeListeners')) {
      this.setRouteListeners('itemsGoBack');
      this.$root.$eventHub.$on('itemsGoBack', data => {
        this.itemsGoBack();
        this.$root.$eventHub.$off('itemsGoBack'); //解绑当前监听，否则下轮监听还会持续叠加
      });
      this.SmartStorage.remove('carItem');
    } else {
      this.redirectRouteListeners(); //记录全局路由轨迹
      this.$root.$eventHub.$on('itemsGoBack', data => {
        this.itemsGoBack();
      });
    }
    this.checkItem();
    this.QueryItems();
  },
  components: {
    itemInfo
  },
  methods: {
    //是否可以添加需求
    async checkItem() {
      let param = {
        proposalId: this.SmartStorage.get('proposalId'),
        isOnline: false,
        sourceCode: ''
      };
      let _this = this;
      this.services
        .CheckCanNewItem(param)
        .then(function(res) {
          _this.checkCanNewItem = res.content;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    redirectRouteListeners() {
      this.SmartStorage.set('routeListeners', ['itemsGoBack']);
    },

    itemClick(item, btn) {
      this.choiseItem = item;
      this[btn.processor](item);
    },
    edit(editaCar) {
      console.log('editaCar==',editaCar);
      let data = {
        dtStart: vueFilter.formatDate(editaCar.dtStart, 'yyyy-MM-dd hh:mm'),
        carModus: editaCar.carModus,
        carType: editaCar.carType,
        descr: editaCar.descr,
        amount: editaCar.amount,
        supplierName: editaCar.supplierName,
        city: editaCar.city,
        province: editaCar.province,
        supplierId: editaCar.supplierId,
        price: editaCar.price,
        qty: editaCar.qty1,
        itemId: editaCar.itemId,
        isSpecial: editaCar.itsItemData.isSpecial ? '1' : '0',
        proposalId: editaCar.proposalId,
        itemData: {
          styleId: editaCar.itsItemData.styleId,
          typeId: editaCar.itsItemData.typeId,
          lesserAreas: editaCar.itsItemData.lesserAreas,
          zoneId: editaCar.itsItemData.lesserAreas && editaCar.itsItemData.lesserAreas.zoneId,
          isSpecial: editaCar.itsItemData.isSpecial,
          departure: editaCar.itsItemData.departure,
          destination: editaCar.itsItemData.destination,
          tripList: editaCar.itsItemData.tripList,
          supplierThumbnail: editaCar.itsItemData.supplierThumbnail,
          mileage: editaCar.itsItemData.mileage,
          startLngLat: editaCar.itsItemData.startLngLat,
          endLngLat: editaCar.itsItemData.endLngLat,
          passenger:{
            name: editaCar.itsItemData.passenger.name
          },
          passenger_phone: editaCar.itsItemData.passenger_phone,
          puCity: editaCar.itsItemData.puCity,
          destCity: editaCar.itsItemData.destCity,
        }
      };
      this.goEditOrder(data);
    },
    remove() {
      this.messageTxt = '确定删除该需求吗';
      this.messageType = 'remove';
      this.$refs.msg.openPop();
    },
    messageBtn() {
      this.messageType == 'remove' ? this.deletItem() : this.submitBtn();
    },
    deletItem() {
      let param = { itemId: this.choiseItem.itemId };
      let _this = this;
      this.services.DeleteItem(param).then(res => {
        if (res.success) {
          _this.$iToast('删除成功');
          _this.QueryItems();
        }
      });
    },
    SubmitAllItem(data) {
      console.log(data);
      if (this.orderList.length < 1) {
        this.$iToast('请先添加需求！');
        return false;
      }
      this.messageTxt = '请您确保所有需求已添加完成后，再进行提交！';
      this.messageType = 'submit';
      this.$refs.msg.openPop();
    },
    submitBtn() {
      let param = { proposalId: this.SmartStorage.get('proposalId') };
      let _this = this;
      this.isloding = true;
      this.$iDelay(() => {
        this.services.SubmitAllItem(param).then(res => {
          if (res.success) {
            this.$iDelay(() => {
              _this.isloding = false;
              _this.itemsGoBack();
            }, 3000);
          } else {
            _this.isloding = false;
          }
        });
      }, 400);
    },
    goEditOrder(data) {
      this.SmartStorage.set('checkCanNewItem', this.checkCanNewItem);
      if (data) {
        this.SmartStorage.set('carItem', data);
        this.$router.push('/cityEditOrder');
      } else {
        this.$iMessageBox.render({
          btnType: 'single',
          singleTxt: '确定',
          messageTitle: '提示',
          messageTxt: '城市车队必须提前48小时预约，否则可能无法预约成功。',
          onSingle: function() {
            this.$router.push('/cityEditOrder');
          }.bind(this)
        });
      }
      // this.$router.push("/cityEditOrder");
    },
    itemsGoBack() {
      if (this.SmartStorage.get('routeListeners')[0] != 'itemsGoBack') {
        this.SmartStorage.remove('carOrder');
        this.SmartStorage.remove('taskId');
        this.removeRouteListeners('itemsGoBack');
        this.$router.push('/cityIndex');
      } else {
        const container = this.$cookies.get('container');
        switch (container) {
          case 'browser':
            this._browserBack();
            break;
          case 'h5':
            this._h5Back();
            break;
          case 'native':
          default:
            this.closeAppWithReload();
        }
      }
    },
    QueryItems() {
      let _this = this;
      this.isFirst = true;
      let param = { proposalId: this.SmartStorage.get('proposalId') };
      this.services.QueryItems(param).then(res => {
        if (res.success) {
          for (let i = 0; i < res.content.length; i++) {
            res.content[i].descr = _this.StringExchangeEmoji(res.content[i].descr);
          }
          _this.orderList = res.content;
          _this.isFirst = false;
        }
      });
    },
   
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/tenant/item.scss';
/deep/.rightBtn{
div{
  font-size: .13rem;
}
}
</style>
